@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
body {
  margin: 0;
  background-color: #FAFAFA;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #232936;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  padding: 5em 0 5em 0;
}

a, a:visited {
  color: #FAFAFA;
}
.navbar {
    background-color: #1E1F21;
}

.navbar .container {
    width: 90%;
    max-width: 90%;
}

.brand-logo {
    font-family: 'Abril Fatface', cursive;
}

footer {
    background-color: #1E1F21;
    padding: 3em 1em 3em 1em;
    color: #FAFAFA;
}

#mailIcon {
    font-size: 3em;
}

#socialNav {
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    margin: auto;
}
#hero {
    background-image: url(/static/media/shinnosuke-kawabe-idya-9U4ZJM-unsplash.ce771758.webp);
    background-color: rgba(4,48,71,1);
    height: 85vh;
    background-size: cover;
    margin: 0;
}

h1 {
    font-family: 'Abril Fatface', cursive;
    margin-top: 4em;
}

h2 {
    font-size: 2rem;
    margin-top: 0;
}

@media only screen and (min-width: 768px) {
    h1 {
        margin-top: 3em;
        font-size: 6.5rem;
    }
}

@media only screen and (min-width: 1500px) {
    h1 {
        margin-left: 40% !important;
    }
}
.defaultPosts {
    max-width: 800px;
    margin: auto;
}

h4 {
    text-align: center;
    margin: 0 0 2rem 0;
}
#latestContainer {
    display: flex;
}

#twitterFeed {
    width: 500px;
    height: 300px;
    overflow-y: scroll;
}
#curator-feed-latestpage-layout {
    width: 50%;
}
