.navbar {
    background-color: #1E1F21;
}

.navbar .container {
    width: 90%;
    max-width: 90%;
}

.brand-logo {
    font-family: 'Abril Fatface', cursive;
}
