#hero {
    background-image: url("../../assets/shinnosuke-kawabe-idya-9U4ZJM-unsplash.webp");
    background-color: rgba(4,48,71,1);
    height: 85vh;
    background-size: cover;
    margin: 0;
}

h1 {
    font-family: 'Abril Fatface', cursive;
    margin-top: 4em;
}

h2 {
    font-size: 2rem;
    margin-top: 0;
}

@media only screen and (min-width: 768px) {
    h1 {
        margin-top: 3em;
        font-size: 6.5rem;
    }
}

@media only screen and (min-width: 1500px) {
    h1 {
        margin-left: 40% !important;
    }
}