footer {
    background-color: #1E1F21;
    padding: 3em 1em 3em 1em;
    color: #FAFAFA;
}

#mailIcon {
    font-size: 3em;
}

#socialNav {
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    margin: auto;
}